<template>
  <div class="field-container">
    <is-input :value="form_data.title" :meta="form_meta.title" @input="updateVal('title', $event)"
              :horizontal="horizontal"/>

    <is-input :value="form_data.first_name" :meta="form_meta.first_name" :horizontal="horizontal"
              :required="form_data.required"
              @input="updateVal('first_name', $event)"/>

    <is-input :value="form_data.last_name" :meta="form_meta.last_name" :horizontal="horizontal"
              :required="form_data.required"
              @input="updateVal('last_name', $event)"/>

    <is-input :value="form_data.org" :meta="form_meta.org" @input="updateVal('org', $event)"
              :required="form_data.required"
              :horizontal="horizontal" :autocomplete="orgs" :loading="loading"/>

    <is-field label="Bereich" :type="area_type" :horizontal="horizontal" :required="true">
      <b-select required :value="form_data.area" @input="updateVal('area', $event)">
        <option value="">-- bitte wählen --</option>
        <option value="Forschungsförderung">Forschungsförderung</option>
        <option value="KunstDesignArch">Kunst, Design, Architektur</option>
        <option value="NGO">NGO</option>
        <option value="Politik">Politik</option>
        <option value="Verwaltung">Verwaltung</option>
        <option value="Wirtschaft">Wirtschaft</option>
        <option value="Wissenschaft">Wissenschaft/Forschung</option>
        <option value="Andere">Andere</option>
      </b-select>
    </is-field>

    <is-input :value="form_data.email" :meta="form_meta.email" @input="updateVal('email', $event)"
              :horizontal="horizontal"/>

    <is-input :value="form_data.phone" :meta="form_meta.phone" @input="updateVal('phone', $event)"
              :horizontal="horizontal"/>
  </div>
</template>


<script>
import {required, email, numeric} from 'vuelidate/lib/validators'
import _ from 'lodash'
import {getFieldErrorMessage, getPreloadData} from '../js/tools'
import IsField from "./buefy-overrides/Field.vue";

let preload = getPreloadData();

let formMetaTemplate = {
  title: {
    label: 'Titel',
    name: 'title',
    type: 'text',
  },
  first_name: {
    label: 'Vorname',
    name: 'first_name',
    type: 'text',
    required: true
  },
  last_name: {
    label: 'Nachname',
    name: 'last_name',
    type: 'text',
    required: true
  },
  email: {
    label: 'E-Mail',
    name: 'email',
    type: 'email',
    required: true
  },
  org: {
    label: 'Institution',
    name: 'org',
    type: 'text',
    required: true,
  },
  area: {
    label: 'Bereich',
    name: 'bereich',
    type: 'select',
    required: true,
  },
  phone: {
    label: 'Telefon',
    name: 'phone',
    type: 'text',
    required: true,
  },
  access_code: {
    label: 'Zugangscode',
    name: 'access_code',
    type: 'password',
    disabled: true,
    pw_reveal: true,
  }
};

let formDataTemplate = {};
for (let x of _.keys(formMetaTemplate)) {
  formDataTemplate[x] = '';
  _.assign(formMetaTemplate[x], {dirty: false, error: ''})
}

export default {
  components: {IsField},
  props: {
    horizontal: {},
    userData: {}
  },
  data() {
    return {
      loading: false,
      form_data: _.assign({}, formDataTemplate, this.userData),
      form_meta: _.assign({}, formMetaTemplate),
      orgs: preload.typeahead.institutions,
    };
  },
  validations: {
    form_data: {
      first_name: {required},
      last_name: {required},
      area: {required},
      email: {required, email},
      phone: {numeric, required}
    },
  },
  methods: {
    updateVal(name, value) {
      this.form_data[name] = value;
      this.form_meta[name].dirty = true;

      if (this.$v.form_data[name]) {
        this.$v.form_data[name].$touch();
        this.check_errors(name);
      }
      this.$emit('input', this.form_data)
    },
    check_errors(name) {
      let $v = this.$v.form_data[name];
      let input_field_meta = this.form_meta[name];
      let params = $v.$flattenParams();
      for (let p of params) {
        let error_value = $v[p.name];
        let hasError = !error_value;
        if (hasError) {
          input_field_meta.error = getFieldErrorMessage(p);
          return;
        }
      }
      input_field_meta.error = '';
    }
  },
  computed: {
    is_loading_class() {
      return this.loading ? 'is-loading' : ''
    },
    invalid_data() {
      return this.$v.form_data.$invalid
    },
    area_type() {
      return this.form_data.area ? '' : 'is-danger'
    },
  },
  watch: {
    invalid_data(newVal) {
      this.$emit('invalid', newVal)
    },
    userData(newVal) {
      this.form_data = _.assign({}, formDataTemplate, this.userData);
    }
  },
  mounted() {
    this.$emit('invalid', this.invalid_data)
  }
}
</script>