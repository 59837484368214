<template>
    <div class="field" :class="[fieldType, newPosition, {
        'is-expanded': expanded,
        'is-grouped-multiline': groupMultiline,
        'is-horizontal': horizontal
    }]">
        <template v-if="horizontal">
            <div class="field-label is-normal">
                <label class="label" :for="labelFor" v-if="label">{{ label }}<span v-if="required">*</span></label>
            </div>
            <div class="field-body">
                <div class="field">
                    <div class="control">
                        <slot></slot>
                    </div>
                    <p class="help" :class="newType" v-if="newMessage" v-html="formattedMessage"></p>
                </div>
            </div>
        </template>

        <template v-else>
            <label class="label" :for="labelFor" v-if="label">{{ label }}<span v-if="required">*</span></label>
            <slot></slot>
            <p class="help" :class="newType" v-if="newMessage" v-html="formattedMessage"></p>
        </template>
    </div>
</template>

<script>
    import Buefy from 'buefy';

    export default {
        name: 'isField',
        extends: Buefy.Field,
        props: ['required'],
        created() {
            this.$parent.$on('/')
        }
    }
</script>