<!--suppress HtmlUnknownTarget, JSUnresolvedFunction -->
<template>
  <div class="form columns">
    <div class="column is-8-desktop is-offset-2-desktop">


      <!--   PERSONENDATEN   -->

      <h2 class="title is-2">Einreichende Person / Kontaktperson</h2>
      <p>Ist die einreichende Person eine andere als die registrierte Person, dann fügen Sie bitte die
        Kontaktdaten der
        einreichenden Person hinzu.</p><br/>
      <div>
        <is-contact-person :horizontal="true" @invalid="updateValue('invalid.person', $event)"
                           @input="updateValue('sub.person', $event)"
                           :user-data="sub.person"></is-contact-person>
        <info-text>Das ist die Erklärung zum Befüllen der Angaben!</info-text>
        <sk-show-errors v-model="$v.sub.person"></sk-show-errors>
      </div>


      <!--   ART   -->

      <h2 class="title is-2">Art der Einreichung</h2>

      <!--   Art: WISS   -->
      <div class="field">
        <b-radio v-model="sub.type" native-value="wiss" :disabled="!active_sub.wiss && !is_admin">
          <tr-text trkey="subform-1-wiss-sess">Beitrag für die allgemeinen wissenschaftlichen Sessions</tr-text>
        </b-radio>
        <info-text trkey="subform-1-wiss-sess-i"></info-text>
      </div>

      <!--   Art: ACRP   -->
      <div class="field">
        <b-radio v-model="sub.type" native-value="acrp" :disabled="!active_sub.acrp && !is_admin">
          <tr-text trkey="subform-1-acrp">Beitrag zur ACRP-Qualitätssicherung im Beisein
            des ACRP Steering Committees
          </tr-text>
        </b-radio>
        <!-- info-text(kind="warning") <b class="has-text-danger">ACHTUNG! Der Call for Abstracts für die ACRP-Poster-Session ist geschlossen!</b> Bei Fragen kontaktieren Sie bitte das Klimatag-Team unter <a href="mailto:klimatag@ccca.ac.at">klimatag@ccca.ac.at</a>.-->
        <info-text trkey="subform-1-acrp-i"></info-text>
      </div>

      <!--   Art: NAWU   -->
      <div class="field">
        <b-radio v-model="sub.type" native-value="nachwuchs" :disabled="!active_sub.nachwuchs && !is_admin">
          <tr-text trkey="subform-1-nachwuchs">Beitrag für den CCCA Nachwuchspreis</tr-text>
        </b-radio>
        <!-- info-text(kind="warning") <b class="has-text-danger">ACHTUNG! Der Call for Abstracts für die ACRP-Poster-Session ist geschlossen!</b> Bei Fragen kontaktieren Sie bitte das Klimatag-Team unter <a href="mailto:klimatag@ccca.ac.at">klimatag@ccca.ac.at</a>.-->
        <info-text trkey="subform-1-nawu-i"></info-text>
      </div>

      <!--	ABSTAND?	-->
      <div class="field">&nbsp;</div>

      <!--   IF NAWU   -->
      <template v-if="sub.type === 'nachwuchs'">
        <div class="field">
          <is-field label="Titel des eingereichten Papers">
            <b-input v-model="sub.detail.title_paper" @input="updateValue('sub.detail.title_paper', $event)"></b-input>
          </is-field>
          <sk-show-errors v-model="$v.sub.detail.title_paper"></sk-show-errors>
        </div>
        <div class="field">
          <is-field
              label="Name des Journals, in dem das eingereichte Paper veröffentlicht oder eingereicht wurde">
            <b-input v-model="sub.nawu.journal" @input="updateValue('sub.nawu.journal', $event)"></b-input>
          </is-field>
          <info-text>Bitte geben Sie zwischen 10 und 180 Zeichen ein</info-text>
          <sk-show-errors v-model="$v.sub.nawu.journal"></sk-show-errors>
        </div>
        <div class="field">
          <is-field label="Publikations- bzw. Einreichdatum beim Journal">
            <b-input v-model="sub.nawu.datum" placeholder="MM JJJJ"
                     @input="updateValue('sub.nawu.datum', $event)"></b-input>
          </is-field>
          <info-text>Verwenden Sie bitte folgendes Format:&nbsp;<code>MM JJJJ</code></info-text>
          <sk-show-errors v-model="$v.sub.nawu.datum"></sk-show-errors>
        </div>
        <div class="field">
          <is-field label="Nachweis von Einzel- oder Erstautor:innschaft oder Co-Autor:innenschaft">
            <b-switch v-model="sub.nawu.erstautor">{{
                sub.nawu.erstautor ? 'Ja, ich bin Einzel- oder Erstautor:in!' : 'Nein, es liegt eine Co-Autor:innenschaft vor!'
              }}
            </b-switch>
          </is-field>
        </div>
        <div class="content">
          <p>Bitte laden Sie ein Beweisdokument hoch, welches Ihre
            {{ sub.nawu.erstautor ? '' : 'Co-' }}Autor:innenschaft belegt.</p>
        </div>
        <is-field v-if="sub.nawu.beweis" label="Aktuelle Datei"><a target="_blank"
                                                                   :href="'/uploads/' + sub.nawu.beweis">Datei
          in einem
          neuem Fenster öffnen</a></is-field>
        <div class="field">
          <sk-upload v-model="sub.nawu.beweis" :resize="false" accept=".jpg,.jpeg,.png,.pdf"></sk-upload>
          <info-text>Erlaubte Dateiformate sind JPEG, PNG und PDF.</info-text>
          <info-text>Bei Co-Autor:innenschaften ist bei der Einreichung anzugeben, wie sich die Erstellung der Arbeit
            unter den Co-Autor:innen aufgeteilt hat (mindestens 50% des Textes sind selbst zu verfassen).
          </info-text>
          <sk-show-errors v-model="$v.sub.nawu.beweis"></sk-show-errors>
        </div>
        <div class="content"><b>Alter des/der Autor:in</b>
          <p>Bitte laden Sie ein Beweisdokument hoch, welches Ihr akademisches Alter belegt!</p>
        </div>
        <is-field v-if="sub.nawu.doc_alter" label="Aktuelle Datei"><a target="_blank"
                                                                      :href="'/uploads/' + sub.nawu.doc_alter">Datei
          in
          einem neuem Fenster öffnen</a></is-field>
        <div class="field">
          <sk-upload v-model="sub.nawu.doc_alter" :resize="false" accept=".jpg,.jpeg,.png,.pdf"></sk-upload>
          <info-text>Alter des/der Autor:in: max. 5 Jahre nach höchstem akademischen Abschluss</info-text>
          <info-text>Erlaubte Dateiformate sind JPEG, PNG und PDF.</info-text>
          <sk-show-errors v-model="$v.sub.nawu.doc_alter"></sk-show-errors>
        </div>
        <div class="content"><b>Status des Papers</b>
          <p>Bitte laden Sie eine Kopie des "Editorial Decision Email" des jeweiligen Journals hoch.</p>
        </div>
        <is-field v-if="sub.nawu.doc_editorial_decision" label="Aktuelle Datei"><a target="_blank"
                                                                                   :href="'/uploads/' + sub.nawu.doc_editorial_decision">Datei
          in einem neuem Fenster öffnen</a></is-field>
        <div class="field">
          <sk-upload v-model="sub.nawu.doc_editorial_decision" :resize="false"
                     accept=".jpg,.jpeg,.png,.pdf"></sk-upload>
          <info-text>Erlaubte Dateiformate sind JPEG, PNG und PDF.</info-text>
          <sk-show-errors v-model="$v.sub.nawu.doc_editorial_decision"></sk-show-errors>
        </div>

        <div class="content"><b>Paper hochladen</b>
          <p>Bitte laden Sie das Paper, welches Sie für den Nachwuchspreis einreichen, hoch.</p>
        </div>

        <is-field v-if="sub.nawu.paper" label="Aktuelle Datei">
          <a target="_blank" :href="'/uploads/' + sub.nawu.paper">
            Datei in einem neuem Fenster öffnen
          </a>
        </is-field>

        <div class="field">
          <sk-upload v-model="sub.nawu.paper" :resize="false" accept=".pdf"></sk-upload>
          <info-text>Erlaubtes Dateiformat ist PDF.</info-text>
          <sk-show-errors v-model="$v.sub.nawu.paper"></sk-show-errors>
        </div>

        <div class="content">
          <tr-text trkey="subform-kriterien-nawu">
            Kriterien NAWU Erklärung
          </tr-text>
        </div>
      </template>


      <h2 class="title is-2">Details zum Projekt</h2>
      <is-field :horizontal="false" :label="static.labels.gefoerdert">
        <b-switch v-model="sub.projekt.gefoerdert" :disabled="sub.type === 'acrp'">
          {{ sub.projekt.gefoerdert ? 'Ja, das Projekt ist gefördert!' : 'Nein, es besteht keine Förderung!' }}
        </b-switch>
      </is-field>
      <br/>
      <sk-show-errors v-model="$v.sub.projekt.gefoerdert"></sk-show-errors>
      <div v-if="sub.projekt.gefoerdert">
        <div class="field">
          <is-field :label="static.labels.foerderer"></is-field>
        </div>
        <div class="field">
          <is-field v-for="p in static.foerderprogramme" :key="p.name">
            <b-checkbox v-model="sub.projekt.foerderer" :native-value="p.name">{{ p.label }}
            </b-checkbox>
          </is-field>
          <sk-show-errors v-model="$v.sub.projekt.foerderer"></sk-show-errors>
        </div>
        <div class="field">
          <b-field :label="static.labels.projektakronym">
            <sk-field-autocomplete v-model="sub.projekt.akronym"
                                   :autocomplete="static.typeahead.akronym"></sk-field-autocomplete>
          </b-field>
          <sk-show-errors v-model="$v.sub.projekt.akronym"></sk-show-errors>
        </div>
        <div class="field">
          <b-field :label="static.labels.call">
            <sk-field-autocomplete v-model="sub.projekt.call"
                                   :autocomplete="static.typeahead.call"></sk-field-autocomplete>
          </b-field>
          <sk-show-errors v-model="$v.sub.projekt.call"></sk-show-errors>
        </div>
        <div class="field">
          <is-field :label="static.labels.laufzeit">
            <b-input v-model="sub.projekt.laufzeit" placeholder="MM JJJJ - MM JJJJ"
                     @input="updateValue('sub.projekt.laufzeit', $event)"></b-input>
          </is-field>
          <info-text>Verwenden Sie bitte folgendes Format:&nbsp;<code>MM JJJJ - MM JJJJ</code>
          </info-text>
          <sk-show-errors v-model="$v.sub.projekt.laufzeit"></sk-show-errors>
        </div>
      </div>
      <h2 class="title is-2">Details zur Einreichung</h2>
      <div class="field">
        <is-field :label="static.labels.title">
          <b-input v-model="sub.detail.title" @input="updateValue('sub.detail.title', $event)"></b-input>
        </is-field>
        <info-text>Bitte geben Sie zwischen 10 und 180 Zeichen ein</info-text>
        <sk-show-errors v-model="$v.sub.detail.title"></sk-show-errors>
      </div>
      <div class="field" v-if="sub_type !== 'nachwuchs'">
        <is-field :label="static.labels.detail_type" :addons="false">
          <b-radio v-model="sub.detail.type" native-value="vortrag">
            Vortrag
          </b-radio>
          <b-radio v-model="sub.detail.type" native-value="poster" :disabled="sub.type === 'acrp'">Poster</b-radio>
          <b-radio v-model="sub.detail.type" native-value="egal" :disabled="sub.type === 'acrp'">egal</b-radio>
          <b-radio v-model="sub.detail.type" native-value="inno" :disabled="sub.type !== 'wiss'">Innovatives Format
          </b-radio>
        </is-field>
        <info-text v-if="sub.type === 'acrp'" trkey="subform-beitragsart-poster-acrp-i"></info-text>
        <info-text v-if="poster_session_mismatch || inno_session_mismatch" kind="warning">Sie haben eine ungültige Wahl
          getroffen!
        </info-text>
      </div>
      <h3 class="title is-3">Autor:innen</h3>
      <div class="field" v-for="(a, idx) in sub.detail.authors">
        <p>{{ idx + 1 }}. Autor:in<a @click="delete_author(idx)"><span class="icon"><i
            class="fas fa-trash-alt"></i></span></a><a @click="move_author(idx, 'up')"><span class="icon"><i
            class="fas fa-angle-up"></i></span></a><a @click="move_author(idx, 'down')"><span
            class="icon"><i
            class="fas fa-angle-down"></i></span></a></p>
        <is-field grouped="grouped">
          <b-field label="Vorname">
            <b-input v-model="a.first_name" @input="updateAuthor(idx, 'first_name', $event)"></b-input>
          </b-field>
          <b-field label="Nachname">
            <b-input v-model="a.last_name" @input="updateAuthor(idx, 'last_name', $event)"></b-input>
          </b-field>
          <b-field label="Institution" expanded="expanded">
            <sk-field-autocomplete v-model="a.org" :autocomplete="static.typeahead.institutions"
                                   @input="updateAuthor(idx, 'org', $event)"></sk-field-autocomplete>
          </b-field>
        </is-field>
      </div>
      <sk-show-errors v-model="$v.sub.detail.authors"></sk-show-errors>
      <div class="field">
        <button class="button" @click="add_author()">+ Autor:in</button>
      </div>
      <div class="field">
        <info-text>Die Autor:innen werden in der angegebenen Reihenfolge für Programm und Tagungsband
          des
          Klimatags
          übernommen
        </info-text>
      </div>
      <h3 class="title is-3">Inhaltliche Zuordnung</h3>
      <div class="field">
        <is-field label="Angelehnt an den Österreichischen Sachstandsbericht Klimawandel 2014 (AAR14)"></is-field>
      </div>
      <div class="field">
        <is-field v-for="p in static.aar14_zuordnung" :key="p.name">
          <b-checkbox v-model="sub.detail.aar14" :native-value="p.name">{{ p.label }}</b-checkbox>
        </is-field>
        <info-text>Bitte wählen Sie mindestens 1 Kategorie!</info-text>
        <info-text>Diese Angaben dienen als Unterstützung für die thematische Zuordnung des Abstracts zu den
          Gutachter:innen und für die Gestaltung des konkreten Programms durch das Programmkomitee.
        </info-text>
        <sk-show-errors v-model="$v.sub.detail.aar14"></sk-show-errors>
      </div>


      <!--   Disziplinen   -->
      <div class="field">
        <is-field :label="label_wiss_disz">
          <b-input v-model="sub.detail.disziplinen"
                   @input="updateValue('sub.detail.disziplinen', $event)"></b-input>
        </is-field>
      </div>

      <div class="field">Erkannte Disziplinen:&nbsp;<span
          v-for="tag in array_from_csv(sub.detail.disziplinen)"><span
          class="tag is-info" v-if="tag">{{ tag }}</span>&nbsp;</span></div>
      <div class="field">
        <info-text>Trennen Sie Disziplinen bitte mit einem Strichpunkt (;).</info-text>
        <sk-show-errors v-model="$v.sub.detail.disziplinen"></sk-show-errors>
      </div>

      <!--   SDGs   -->
      <div class="field">
        <is-field label="Zuteilung zu Sustainable Development Goals (SDGs)"></is-field>
      </div>
      <div class="field">
        <is-field v-for="(p,idx) in static.sdg_zuordnung" :key="idx+1">
          <b-checkbox v-model="sub.detail.sdg" :native-value="idx+1">SDG {{ idx + 1 }}: {{ p }}
          </b-checkbox>
        </is-field>
        <info-text>Bitte wählen Sie mindestens 1 SDG!</info-text>
        <sk-show-errors v-model="$v.sub.detail.sdg"></sk-show-errors>
      </div>

      <!--	ABSTRACT INHALTE	-->
      <h2 v-if="sub.type !== 'kunst'" class="title is-2">Abstract Inhalte</h2>
      <h2 v-if="sub.type === 'kunst'" class="title is-2">Projektbeschreibung Inhalt</h2>
      <template v-if="sub.type !== 'kunst'">
        <div class="content">
          <tr-text trkey="subform-abstract-inhalte"></tr-text>
        </div>
        <div class="field" v-if="sub.detail.type === 'inno'">
          <sk-textarea :value="sub.abstract.praesentationsformat" label="Präsentationsformat"
                       @input="updateValue('sub.abstract.praesentationsformat', $event)"></sk-textarea>
          <sk-show-errors :value="$v.sub.abstract.praesentationsformat"></sk-show-errors>
        </div>
        <div class="field">
          <sk-textarea :value="sub.abstract.themenstellung"
                       label="Themenstellung"
                       @input="updateValue('sub.abstract.themenstellung', $event)"></sk-textarea>
          <sk-show-errors :value="$v.sub.abstract.themenstellung"></sk-show-errors>
        </div>
        <div class="field">
          <sk-textarea :value="sub.abstract.methode" label="Methode"
                       @input="updateValue('sub.abstract.methode', $event)"></sk-textarea>
          <sk-show-errors :value="$v.sub.abstract.methode"></sk-show-errors>
        </div>
        <div class="field">
          <sk-textarea :value="sub.abstract.ergebnisse" label="Ergebnisse"
                       @input="updateValue('sub.abstract.ergebnisse', $event)"></sk-textarea>
          <sk-show-errors :value="$v.sub.abstract.ergebnisse"></sk-show-errors>
        </div>
        <h3 class="title is-3">Text Gesamt</h3>
        <div class="field">
          <info-text>{{ abstract_lines_total }} Zeilen in Summe</info-text>
        </div>
        <div class="field">
          <sk-show-errors :value="$v.abstract_lines_total"></sk-show-errors>
        </div>
      </template>
      <template v-if="sub.type === 'kunst'">
        <div class="content">
          <tr-text trkey="subform-abstract-inhalte-kunst"></tr-text>
        </div>
        <h3 class="title is-3">Abbildung</h3>
        <div class="field">
          <sk-textarea :value="sub.abstract.projektbeschreibung" label="Projektbeschreibung" :min-length="3000"
                       :max-length="5500"
                       @input="updateValue('sub.abstract.projektbeschreibung', $event)"></sk-textarea>
          <sk-show-errors :value="$v.sub.abstract.projektbeschreibung"></sk-show-errors>
        </div>
        <div class="field">
          <sk-show-errors :value="$v.abstract_lines_total"></sk-show-errors>
        </div>
      </template>
      <h3 class="title is-3">Abbildung</h3>
      <div class="field">
        <sk-upload v-model="sub.abstract.abbildung.filename" :resize="true"
                   accept=".jpg,.jpeg,.png"></sk-upload>
        <info-text>Erlaubte Dateiformate sind JPEG und PNG. Bild wird auf 16x7cm bei mindestens 150dpi
          dargestellt.
          Größere Auflösungen werden proportional skaliert. Kleinere werden bei 150dpi (verkleinert)
          dargestellt.
        </info-text>
        <sk-show-errors v-model="$v.sub.abstract.abbildung.filename"></sk-show-errors>
      </div>
      <div v-if="sub.abstract.abbildung.filename">
        <h4 class="title is-4">Aktuelle Abbildung</h4>
        <div class="content">
          <p>Das dargestellte Bild ist eine Vorschau. Wenn Sie eine naturgetreue Darstellung Bildes sehen
            möchten,
            dann wählen Sie bitte "Bild in einem neuem Fenster öffnen". Das erlaubt Ihnen die Kontrolle
            des Bildes in
            Ihrer endgültigen Größe nach der Verarbeitung (Verkleinerung) durch unseren Server.</p>
        </div>
        <div class="field">
          <div class="image is-16by7 box"><img :src="'/uploads/' + sub.abstract.abbildung.filename" alt="abbildung"/>
          </div>
        </div>
        <div class="field">
          <is-field label="Bild Beschriftung">
            <b-input v-model="sub.abstract.abbildung.text"
                     @input="updateValue('sub.abstract.abbildung.text', $event)"></b-input>
          </is-field>
        </div>
        <div class="field">
          <a target="_blank" :href="'/uploads/' + sub.abstract.abbildung.filename">
            Bild in einem neuem Fenster öffnen
          </a>
        </div>
      </div>
      <div class="field"></div>


      <!--	SONSTIGE	-->

      <h2 class="title is-2">Sonstige Angaben</h2>
      <div class="field">
        <is-field label="Alternativsprache Englisch">
          <b-switch v-model="sub.sonstige.englisch">{{
              sub.sonstige.englisch ? 'Ja, ich möchte auf Englisch vortragen!' : 'Nein, ich möchte NICHT auf Englisch vortragen!'
            }}
          </b-switch>
        </is-field>
        <info-text>Bevorzugte Vortragssprache am Klimatag ist Deutsch. Bei Bedarf können Vorträge auch auf
          Englisch gehalten werden.
        </info-text>
      </div>


      <!--	SAVE /  PUBLISH	-->

      <h2 class="title is-2">Speichern und Einreichen</h2>
      <div class="content">
        <tr-text trkey="subform-speichern-einreichen">
          Speichern Erklärung
        </tr-text>

      </div>
      <div class="field">
        <is-field label="Freigeben">
          <b-switch :disabled="$v.$invalid" v-model="sub.submitted">{{
              sub.submitted ? 'Ja, ich möchte meine Einreichung frei geben!' : 'Nein, ich möchte meine Einreichung noch nicht frei geben!'
            }}
          </b-switch>
        </is-field>
      </div>
      <div class="field">
        <info-text kind="danger" v-if="$v.$invalid">Sie können derzeit nicht freigeben, weil es noch Probleme
          mit Ihren Angaben gibt.
        </info-text>
      </div>
      <div class="content" v-if="sub.submitted">
        <tr-text trkey="subform-freigeben">Freigeben Erklärung</tr-text>
      </div>
      <!-- info-text Änderungen zum eingereichten Abstract sind für die „ACRP-Poster-Session“ sowie für den „Nachwuchspreis“ noch bis zum Donnerstag, 12. Dezember 2019 (23:00) möglich.-->
      <div class="field" v-if="current_selection_active || is_admin">
        <button class="button is-primary" v-if="!sub.submitted" @click="save()"
                :disabled="$v.sub.person.$invalid">
          Entwurf Speichern
        </button>
        <div v-if="$v.sub.person.$invalid"><br/>
          <info-text>Sie können den Entwurf speichern, sobald Sie die Daten der einreichenden Person gültig befüllt
            haben.
          </info-text>
          <info-text kind="danger" v-if="is_admin">Einreichungen sind deaktiviert. Sie sehen den Speichern Knopf weil
            sie ADMIN sind.
          </info-text>
        </div>
        <button class="button is-success" v-if="sub.submitted" @click="save()">Speichern und Einreichen</button>
      </div>
      <div class="field" v-else>
        <info-text kind="danger">Einreichungen sind deaktiviert.</info-text>
      </div>
      <div class="field"><a href="/account">Zurück zum Account</a></div>
      <div class="field"></div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {getPreloadData} from '../js/tools'
import {monthYear, monthYearTimeRange} from '../js/custom_validators'
import {email, maxLength, maxValue, minLength, numeric, required} from 'vuelidate/lib/validators'
import InfoText from "./info-text";

const preload_data = getPreloadData();
const foerderprogramme = preload_data.foerderprogramme;
const typeahead = preload_data.typeahead;
const labels = {
  title: 'Titel',
  gefoerdert: 'Ist das Projekt gefördert?',
  foerderer: 'Förderer des Projekts',
  projektakronym: 'Projektakronym',
  call: 'Call',
  laufzeit: 'Laufzeit',
  detail_type: 'Bevorzugte Art der Präsentation'
};
const aar14_zuordnung = [
  {
    "label": "Klimawandel in Ö: Einflussfaktoren und Ausprägung",
    "name": "klimawandel",
  },
  {
    "label": "Auswirkungen des Klimawandels auf Umwelt und Gesellschaft",
    "name": "auswirkungen",
  },
  {
    "label": "Vermeidung und Anpassung",
    "name": "vermeidung",
  },
  {
    "label": "Neue alternative Perspektiven auf den Klimawandel",
    "name": "perspektiven",
  },
  {
    "label": "Kommunikation",
    "name": "kommunikation",
  }
];

const sdg_zuordnung = ['Keine Armut', 'Kein Hunger', 'Gesundheit und Wohlergehen', 'Hochwertige Bildung', 'Geschlechtergleichheit', 'Sauberes Wasser und Sanitäreinrichtungen', 'Bezahlbare und saubere Energie', 'Menschenwürdige Arbeit und Wirtschaftswachstum', 'Industrie, Innovation und Infrastruktur', 'Weniger Ungleichheiten ', 'Nachhaltige Städte und Gemeinden', 'Nachhaltige/r Konsum und Produktion', 'Maßnahmen zum Klimaschutz', 'Leben unter Wasser', 'Leben am Land', 'Frieden, Gerechtigkeit und starke Institutionen', 'Partnerschaften zur Erreichung der Ziele'];

const authorTemplate = {first_name: '', last_name: '', org: ''};

export default {
  components: {InfoText},
  props: {},
  data() {
    console.log('sdasd', preload_data.active)
    let data_obj = {
      translations: null,
      active: preload_data.active.submission,
      active_sub: {
        wiss: !!preload_data.active?.subm_wiss,
        kunst: !!preload_data.active?.subm_kunst,
        nachwuchs: !!preload_data.active?.subm_nachwuchs,
        acrp: !!preload_data.active?.subm_acrp,
      },
      is_admin: preload_data.is_admin,
      static: {foerderprogramme, labels, typeahead, aar14_zuordnung, sdg_zuordnung},
      sub: {
        person: {},
        type: 'wiss',
        submitted: false,
        projekt: {
          gefoerdert: true,
          foerderer: [],
          akronym: '',
          call: '',
          laufzeit: ''
        },
        detail: {
          title: '',
          type: 'inno',
          authors: [{first_name: '', last_name: '', org: ''}],
          aar14: [],
          sdg: [],
          disziplinen: '',
        },
        sonstige: {
          nawu: true,
          englisch: false,
        },
        nawu: {
          datum: '',
          journal: '',
          erstautor: true,
          beweis: '',
          doc_alter: '',
          paper: '',
        },
        abstract: {
          praesentationsformat: {
            content: '',
            len: 0,
            rows: 0,
          },
          themenstellung: {
            content: '',
            len: 0,
            rows: 0,
          },
          projektbeschreibung: {
            content: '',
            len: 0,
            rows: 0,
          },
          methode: {
            content: '',
            len: 0,
            rows: 0,
          },
          ergebnisse: {
            content: '',
            len: 0,
            rows: 0,
          },
          abbildung: {
            filename: '',
            text: ''
          }
        }
      },
    };

    if (preload_data.sub) {
      data_obj.sub = _.merge(data_obj.sub, preload_data.sub);
    }

    return data_obj;
  },
  methods: {
    updateValueHelper(key, v_key, val) {
      _.set(this, key, val);
      let v_model = _.get(this.$v, v_key, false);
      if (v_model) {
        console.log('touching model');
        v_model.$touch()
      }
    },
    updateValue(key, val) {
      console.log('submission-form::event update', {key, val});
      this.updateValueHelper(key, key, val)
    },
    updateAuthor(idx, key, value) {
      let path = `sub.detail.authors[${idx}].${key}`;
      let path_v = `sub.detail.authors.$each.${idx}.${key}`;
      this.updateValueHelper(path, path_v, value);
    },
    add_author() {
      this.sub.detail.authors.push(_.clone(authorTemplate))
    },
    delete_author(idx) {
      this.sub.detail.authors.splice(idx, 1)
    },
    move_author(idx, dir) {
      console.log({idx, dir});
      let move_up = dir === 'up';
      let authors = this.sub.detail.authors;

      let first_element_check = (move_up && idx === 0);
      let last_element_check = (!move_up && (idx + 1) === authors.length);

      if (first_element_check || last_element_check) {
        console.log('abort move author', first_element_check, last_element_check);
        return;
      }

      let indexModifier = move_up ? -1 : 1;
      let element = authors.splice(idx, 1)[0];
      authors.splice(idx + indexModifier, 0, element);
    },
    array_from_csv(str) {
      str = _.trim(str, ' ;');
      return str.split(';');
    },
    save() {
      console.log('saving: ', this.sub);
      this.$http.post('', this.sub).then((resp) => {
        console.log(resp);
        if (resp.status !== 200) {
          this.$toast.open({
            duration: 5000,
            message: `Fehler beim Speichern!`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        } else {
          window.location.href = '/account';
        }
      });

    }
  },
  computed: {
    poster_session_mismatch() {
      return (this.sub.type === 'acrp') && this.sub.detail.type !== 'vortrag'
    },
    inno_session_mismatch() {
      return (this.sub.type !== 'wiss') && this.sub.detail.type === 'inno'
    },
    abstract_lines_total() {
      let a = this.sub.abstract;
      if (!a.themenstellung && !a.ergebnisse && !a.methode && !a.projektbeschreibung) {
        return 0
      }
      return a.themenstellung.rows + a.ergebnisse.rows + a.methode.rows + a.projektbeschreibung.rows;

    },
    gefoerdert() {
      return _.get(this, 'sub.projekt.gefoerdert', false)
    },
    form_invalid() {
      return this.$v.$invalid
    },
    sub_type() {
      return this.sub.type;
    },
    label_wiss_disz() {
      return 'Wissenschaftsdisziplinen';
    },
    current_selection_active() {
      return this.active_sub[this.sub_type]
    }
  },
  validations() {
    let validations = {
      abstract_lines_total: {maxValueRows: maxValue(57)},
      sub: {
        person: {
          first_name: {
            required
          },
          last_name: {
            required
          },
          email: {
            required, email
          },
          phone: {
            numeric
          }
        },
        projekt: {
          foerderer: {required},
          akronym: {required},
          call: {required},
          laufzeit: {monthYearTimeRange, required},
        },
        detail: {
          title: {required, minLength: minLength(10), maxLength: maxLength(180)},
          authors: {
            required,
            $each: {
              first_name: {required},
              last_name: {required},
              org: {required}
            }
          },
          aar14: {required},
          sdg: {required},
          disziplinen: {required}
        },
        abstract: {
          themenstellung: {
            content: {required, minLength: minLength(1000)},
          },
          methode: {
            content: {required, minLength: minLength(1000)},
          },
          ergebnisse: {
            content: {required, minLength: minLength(1000)},
          },
          abbildung: {}
        },
        nawu: {}
      }
    };

    if (this.poster_session_mismatch) {
      validations.sub.detail.type = {}
    }
    if (this.inno_session_mismatch) {
      validations.sub.detail.type = {}
    }
    if (!this.gefoerdert) {
      validations.sub.projekt = {}
    }

    if (this.sub.type === 'nachwuchs') {
      validations.sub.detail.title_paper = {required, minLength: minLength(10)};
      validations.sub.nawu = {
        journal: {required, minLength: minLength(10), maxLength: maxLength(180)},
        datum: {monthYear, required},
        beweis: {required},
        doc_alter: {required},
        doc_editorial_decision: {required},
        paper: {required},
      }
    }

    if (this.sub_type === 'acrp') {
      validations.sub.projekt.gefoerdert = {required}
    }

    if (this.sub_type === 'kunst') {
      validations.sub.projekt = {}
      validations.sub.detail.aar14 = {}
      validations.sub.abstract = {
        projektbeschreibung: {
          content: {required, minLength: minLength(3000), maxLength: maxLength(5500)},
        },
        abbildung: {
          filename: {required}
        }
      }
    }

    if (this.sub.detail.type === 'inno') {
      validations.sub.abstract.praesentationsformat = {
        content: {required},
      }
    }
    return validations
  },
  watch: {
    form_invalid(newValue) {
      if (newValue) {
        this.sub.submitted = false;
      }
    },
    poster_session_mismatch() {
      this.sub.detail.type = 'vortrag';
    },
    inno_session_mismatch() {
      this.sub.detail.type = 'vortrag';
    },

    sub_type(newValue) {
      if (newValue === 'acrp') {
        this.sub.projekt.gefoerdert = true;
      }
      if (newValue === 'nachwuchs') {
        this.sub.detail.type = 'egal';
      }
    }
  },
  mounted() {
    if (this.form_invalid) {
      this.sub.submitted = false
    }

    if (this.sub.type === 'acrp') {
      this.sub.projekt.gefoerdert = true;
    }


  }
}
</script>
