<template>
  <div class="field" :class="[fieldType, newPosition, {
        'is-expanded': expanded,
        'is-grouped-multiline': groupMultiline,
        'is-horizontal': horizontal
    }]">
    <template v-if="horizontal">
      <div class="field-label is-normal">
        <label class="label" :for="labelFor" v-if="label">{{ label }}<span v-if="required">*</span></label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <slot></slot>
          </div>
          <p class="help" :class="newType" v-if="newMessage" v-html="formattedMessage"></p>
        </div>
      </div>
    </template>

    <template v-else>
      <label class="label" :for="labelFor" v-if="label">{{ label }}<span v-if="required">*</span></label>
      <div class="field-note">{{ note }}</div>
      <slot></slot>
      <p class="help" :class="newType" v-if="newMessage" v-html="formattedMessage"></p>
    </template>

  </div>
</template>

<script>
import Buefy from 'buefy';

export default {
  name: 'isField',
  extends: Buefy.Field,
  props: ['required', 'note'],
  created() {
    this.$parent.$on('/')
  }
}
</script>

<style scoped lang="scss">
.field-note {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  margin-top:0;
}
</style>